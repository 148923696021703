<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row v-if="( getdatosUsuario.idpuesto == 18 && [568, 7, 161].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title class="text-subtitle-1">
            Reporte semanal ventas
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( modalidades  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>


            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'NUEVAS_MATRICULAS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize( )"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text class="black--text">
            <v-row>
              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
	              <v-radio-group
		              v-if="[12,11,40,43,48,25].includes(getdatosUsuario.idpuesto) || [568,28].includes( getdatosUsuario.iderp )"
		              v-model="escuela"
		              row
		              class="ml-4"
		            >
		              <v-radio
		                label="Fast"
		                :value="2"
		              ></v-radio>
		              <v-radio
		                label="Inbi"
		                :value="1"
		              ></v-radio>
		            </v-radio-group>
              </v-col>


	  		    <v-spacer></v-spacer>
            </v-row>

            <v-row v-if="encabezados && getdatosUsuario.idpuesto != 25">
              
              <v-col cols="12" class="py-0">
                <div class="grey--text"><b>Rango de fechas</b></div>
                <div>
                  <span class="font-weight-bold text-h6">{{ encabezados.fecha_inicio_format }}</span> 
                  <span class="font-weight-bold mx-4">al</span> 
                  <span class="font-weight-bold text-h6">{{ encabezados.fecha_final_format }}</span>
                </div>
              </v-col>

            	<v-col cols="12" md="8">
            		<v-card class="elevation-0">
            		  <v-card-text class="black--text">

                    <v-data-table
                      :headers="headers5"
                      :items="datosTablaHeaders5"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="datosTablaHeaders5.length"
                    >
                      <template v-slot:item.columna4="{ item }">
                        <v-icon color="green"  v-if="item.columna6 > item.columna5 "> mdi-arrow-up-bold </v-icon>
                        <v-icon color="red"    v-if="item.columna6 < item.columna5"> mdi-arrow-down-bold </v-icon>
                        <v-icon color="orange" v-if="item.columna6 == item.columna5"> mdi-equal </v-icon>
                        <span 
                          v-if="item.columna6 > 0 &&  item.columna6 < item.columna5 "
                        >
                          {{ ( ( 1 - ( item.columna6 / item.columna5 ) ) * 100 ).toFixed( 2 ) }} % 
                        </span>
                        
                        <span 
                          v-if="item.columna6 > 0 &&  item.columna6 >= item.columna5 "
                        >
                          {{ (( ( item.columna6 / item.columna5 ) - 1) * 100 ).toFixed( 2 ) }} % 
                        </span>
                      </template>
                    </v-data-table>

            		  </v-card-text>
            		</v-card>
            	</v-col>

            	<v-col cols="12" md="4" v-if="encabezados_ant">
            		<v-card outlined>
            		  <v-card-text class="black--text">
		            		<div>Liq. Mat. Anteriores:     <b>{{ encabezados_ant.matriculas_liquidadas_ant }} => {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( encabezados_ant.matriculas_liquidadas_ant_monto ) }} </b> </div>
            		  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
              	<v-card class="elevation-0">
              		<v-card-title primary-title class="py-1">
              			Ventas por sucursal
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
                      :headers="headers"
                      :items="plantelesActivos"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="plantelesActivos.length"
                    >
                    </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>

              <v-col cols="12" lg="6">
              	<v-card class="elevation-0">
              		<v-card-title primary-title class="py-1">
              		  Ventas por vendedora
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
                      :headers="headers2"
                      :items="vendedorasActuales"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="vendedorasActuales.length"
                    >
                    </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>
            </v-row>

            <v-row v-if="getdatosUsuario.idpuesto != 25 ">
              <v-col cols="12" lg="6">
              	<v-card class="elevation-0">
              		<v-card-title primary-title class="py-1">
              			Ventas por sucursal $
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
                      :headers="headers3"
                      :items="plantelesActivos"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="plantelesActivos.length"
                    >
                    	<template v-slot:item.matriculas_nuevas_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_nuevas_monto ) }}
									    </template>
									    <template v-slot:item.matriculas_liquidadas_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_liquidadas_monto ) }}
									    </template>
									    <template v-slot:item.matriculas_parciales_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_parciales_monto ) }}
									    </template>
									    <template v-slot:item.matriculas_ant_liqu_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_ant_liqu_monto ) }}
									    </template>
                    </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>

              <v-col cols="12" lg="6">
              	<v-card class="elevation-0">
              		<v-card-title primary-title class="py-1">
              		  Ventas por vendedora $
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
                      :headers="headers4"
                      :items="vendedorasActuales"
                      class="elevation-0"
                      dense
                      mobile-breakpoint="200"
                      hide-default-footer
                      :items-per-page="vendedorasActuales.length"
                    >
                    	<template v-slot:item.matriculas_nuevas_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_nuevas_monto ) }}
									    </template>
									    <template v-slot:item.matriculas_liquidadas_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_liquidadas_monto ) }}
									    </template>
									    <template v-slot:item.matriculas_parciales_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_parciales_monto ) }}
									    </template>
									    <template v-slot:item.matriculas_ant_liqu_monto="{ item }">
									      {{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.matriculas_ant_liqu_monto ) }}
									    </template>
                    </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers: [
        { text: 'Plantel'         , value: 'plantel'               },
        { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas'     },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
        { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales'  },
        { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu'   },
      ],

      headers2: [
        { text: 'Vendedora'       , value: 'nombre_completo'       },
        { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas'     },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
        { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales'  },
        { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu'   },
      ],

      headers3: [
        { text: 'Plantel'         , value: 'plantel'                     },
        { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas_monto'     },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas_monto' },
        { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales_monto'  },
        { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu_monto'   },
      ],

      headers4: [
        { text: 'Vendedora'       , value: 'nombre_completo'             },
        { text: 'Mat. Nuevas'     , value: 'matriculas_nuevas_monto'     },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas_monto' },
        { text: 'Mat. Nvas. Par.' , value: 'matriculas_parciales_monto'  },
        { text: 'Liq. Mat. Ant'   , value: 'matriculas_ant_liqu_monto'   },
      ],

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      nuevasMatriculas:[],
      plantelesActivos:[],
      vendedorasActuales:[],
      encabezados: null,
      encabezados_ant: null,
      escuela: 1,


      headers5: [
        { text: 'Campo'     , value: 'columna1' },
        { text: 'Semana Ant', value: 'columna2' },
        { text: 'Semana Act', value: 'columna3' },
        { text: 'Avance'    , value: 'columna4' },
      ],
      datosTablaHeaders5:[],
      alumnos:[]

    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.cargar    = true
        this.ciclos    = []

        if( !this.fechaini ){ return this.validarErrorDirecto('Favor de seleccionar un ciclo') }


        var payload = { fechaini: this.fechaini, escuela: [18,19].includes( this.getdatosUsuario.idpuesto ) ? this.getEscuela : this.escuela }

        this.$http.post('reporte.semanal.ventas', payload ).then(response=>{
        	this.encabezados        = response.data.encabezados
        	this.encabezados_ant    = response.data.encabezados
        	this.plantelesActivos   = response.data.plantelesActivos
          this.vendedorasActuales = response.data.vendedorasActuales
        	this.alumnos            = response.data.nuevasMatriculas

          this.datosTablaHeaders5 = [
            { 
              columna1: 'Semena',
              columna2: `${this.encabezados.fecha_inicio_formatAnterior} al ${this.encabezados.fecha_final_formatAnterior}`,
              columna3: `${this.encabezados.fecha_inicio_format} al ${this.encabezados.fecha_final_format}`,
              columna5: 0
            },
            { 
              columna1: 'Mat. Nuevas',
              columna2: ` ${ this.encabezados.matriculas_nuevasSemAnt} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_nuevas_montoSemAnt ) }`,
              columna3: ` ${ this.encabezados.matriculas_nuevas} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_nuevas_monto ) }`,
              columna5: this.encabezados.matriculas_nuevasSemAnt,
              columna6: this.encabezados.matriculas_nuevas,
            },
            { 
              columna1: 'Mat. Nvas. Liq.',
              columna2: ` ${ this.encabezados.matriculas_liquidadasSemAnt} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_liquidadas_montoSemAnt ) }`,
              columna3: ` ${ this.encabezados.matriculas_liquidadas} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_liquidadas_monto ) }`,
              columna5: this.encabezados.matriculas_liquidadasSemAnt,
              columna6: this.encabezados.matriculas_liquidadas,
            },
            { 
              columna1: 'Mat. Nvas. Par.',
              columna2: ` ${ this.encabezados.matriculas_parcialesSemAnt} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_parciales_montoSemAnt ) }`,
              columna3: ` ${ this.encabezados.matriculas_parciales} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_parciales_monto ) }`,
              columna5: this.encabezados.matriculas_parcialesSemAnt,
              columna6: this.encabezados.matriculas_parciales,
            },

            { 
              columna1: 'Contactos',
              columna2: ` ${ this.encabezados.contasctosAnt }`,
              columna3: ` ${ this.encabezados.contasctosAct }`,
              columna5: this.encabezados.contasctosAnt,
              columna6: this.encabezados.contasctosAct,
            }
          ]

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportar(){
        this.exportarData()
      },
    },
  }
</script>